import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import ResponsiveSidebar from "../components/ResponsiveSidebar/ResponsiveSidebar"
import SidenavNavigation from "../components/SidenavNavigation/SidenavNavigation"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      html
      frontmatter {
        title
      }
    }
  }
`

const RiegosTemplate = ({ data: { markdownRemark: riegos } }) => {
  const sidenavNavigationListItems = [
    {
      title: "Goteo",
      url: "/riegos/goteo",
      subItems: [
        {
          title: "Tuberías",
          url: "/riegos/goteo/tuberias",
        },
        {
          title: "Emisores",
          url: "/riegos/goteo/emisores",
        },
        {
          title: "Piecerio",
          url: "/riegos/goteo/piecerio",
        },
        {
          title: "Filtros",
          url: "/riegos/goteo/filtros",
        },
        {
          title: "Programadores",
          url: "/riegos/goteo/programadores",
        },
        {
          title: "Valvulería",
          url: "/riegos/goteo/valvuleria",
        },
      ],
    },
    {
      title: "Pivots y coberturas",
      url: "/riegos/pivots-coberturas",
      subItems: [
        {
          title: "Pivots",
          url: "/riegos/pivots-coberturas/pivots",
        },
        {
          title: "Enrolladores",
          url: "/riegos/pivots-coberturas/enrolladores",
        },
        {
          title: "Coberturas",
          url: "/riegos/pivots-coberturas/coberturas",
        },
      ],
    },
  ]

  return (
    <Layout>
      <div className="container">
        <div className="margin-y-lg">
          <div className="grid gap-xl">
            <div className="col-12">
              <h1 className="text-md">
                <Link className="text-decoration-none" to="/riegos">
                  Riegos
                </Link>
              </h1>
            </div>
            <div className="col-12 col-4@sm">
              <ResponsiveSidebar title="Riegos">
                <SidenavNavigation
                  sidenavNavigationListItems={sidenavNavigationListItems}
                />
              </ResponsiveSidebar>
            </div>
            <div className="col-12 col-8@sm">
              <div className="margin-y-sm text-component">
                <h2 className="text-sm">{riegos.frontmatter.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: riegos.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RiegosTemplate
